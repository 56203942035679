
import React, { useState } from "react";
import image from '../images/krece_logo_new.png';
import { AppBar, Toolbar, Typography, Container, Button, TextField, makeStyles, MenuItem } from '@material-ui/core';
import 'firebase/compat/firestore';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { ArrowBack, ArrowForward, Delete } from '@material-ui/icons';
import { BeatLoader } from 'react-spinners';



const UserPortal = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [cedula, setCedula] = useState('');
    const [loginError, setLoginError] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [payments, setPayments] = useState([]);
    const [amountLeftToPay, setAmountLeftToPay] = useState(0);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [paymentDocId, setPaymentDocId] = useState('');
    const [amountToPayDollars, setAmountToPayDollars] = useState('');
    const [paymentDocRef, setPaymentDocRef] = useState('');
    const [exchangeRate, setExchangeRate] = useState('');
    const [payMethod, setPayMethod] = useState('');
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [clientName, setClientName] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailForAuthCode, setEmailForAuthCode] = useState('');
    const [formError, setFormError] = useState('');
    const [showTokenSent, setShowTokenSent] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [pagoMovilData, setPagoMovilData] = useState({});
    const [transferData, setTransferData] = useState({});

    const goToPage = (x) => {
        setInfoMessage('')
        setLoginError('')
        setPage(x);
    }

    const sendPostRequest = async (data, endpoint, token2) => {
        try {

            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token2
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)

                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setInfoMessage('')
        setLoginError('')
        try {
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'user_check/', token) //status, success, data 
            if (response?.success) {
                if (response.data.is_user_registered && !response.data.can_be_financed) {
                    await getPayments();
                } else if (response.data.is_user_registered && response.data.can_be_financed) {
                    await setLoginError('Este cliente no tiene pagos pendientes con Krece.');
                    setIsLoading(false);
                } else {
                    await setLoginError('No encontramos ningun cliente asociado a esta cédula.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos. ');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos. ');
            setIsLoading(false);
        }
    }

    const getPayments = async () => {
        const paymentsCollection = firestore.collection('Payments');

        // Get a reference to the Firestore collection for users
        const usersCollection = firestore.collection('Users');

        // Query the collection based on the "user" field reference
        try {
            const queryUser = usersCollection
                .where('identification_number', '==', cedula)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setEmail(doc.id);
                    setClientName(doc.data()['first_name'] + " " + doc.data()['last_name'])
                    const query = paymentsCollection
                        .where('user', '==', usersCollection.doc(doc.id))
                        .where('is_active', '==', true)
                        .orderBy('dueDate', 'asc')
                    query.onSnapshot((snapshot) => {
                        const paymentDetails = [];
                        var amountLeft = 0;
                        query.get().then((querySnapshot) => {
                            setIsLoading(true);
                            querySnapshot.forEach((doc) => {
                                // Access the document data
                                amountLeft = amountLeft + doc.data()['actualAmount'] - doc.data()['amountPaid']
                                paymentDetails.push(doc.data());
                                // if (doc.id === 'Q6i4n0DSroCSVSMr6bIT') {
                                //     doc.ref.update({
                                //         amountPaid: 0,
                                //         paid: false,
                                //         checked_by_internal_team: false,
                                //     })
                                // }
                            });
                            setPayments(paymentDetails);
                            setAmountLeftToPay(amountLeft);
                            setIsLoading(false);
                            goToPage(2);
                        }).catch((error) => {
                            setIsLoading(false);
                            alert('Error: ' + error.message)
                        });
                    })
                });
            }).catch((error) => {
                setIsLoading(false);
                alert('Error: ' + error.message)
            })
        } catch (error) {
            setIsLoading(false);
            alert('Error: ' + error.message)
        }
    }

    const showPaymentScreen = () => {
        setIsLoading(true)
        const paymentsCollection = firestore.collection('Payments');

        // Get a reference to the Firestore collection for users
        const usersCollection = firestore.collection('Users');

        // Query the collection based on the "user" field reference
        const query = paymentsCollection
            .where('user', '==', usersCollection.doc(email))
            .where('paid', '==', false)
            .where('is_active', '==', true)
            .orderBy('dueDate', 'asc')
            .limit(1);

        query.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // Access the document data
                const amountToPay = doc.data()['actualAmount'] - doc.data()['amountPaid'];
                setPaymentDocId(doc.id);
                setAmountToPayDollars(amountToPay);
                setPaymentDocRef(doc);
                goToPage(3);
                let paymentReceiver = 'default';
                if (doc.data().store_financed === true) {
                    paymentReceiver = doc.data().related_store
                }
                firestore.collection("StorePaymentMethods").doc(paymentReceiver)
                    .onSnapshot(async (doc) => {
                        if (doc.exists) {
                            setPagoMovilData(doc.data()['pagomovil']);
                            setTransferData(doc.data()['transferBs']);
                        }
                        setIsLoading(false);
                    })
                // setIsLoading(false);

            });
        }).catch((error) => {
            console.log('Error querying payments:', error);
            alert('Ocurrió un problema, intente de nuevo más tarde.')
            setIsLoading(false);
        });
        (async () => {
            try {
                const docRef = await firestore.collection('DollarPrice').doc('dollarPrice');
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        setExchangeRate(doc.data()['bcv'])
                    } else {
                        alert('Ocurrió un problema, intente de nuevo más tarde.')
                        setIsLoading(false);
                    }
                })
                // const response = await getDollarPrices()
                // const rate = response[0].dollar
                // setExchangeRate(rate)
            } catch (error) {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            }
        })();
    }

    const isNumber = async (value) => {
        return /^[+-]?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?(,\d+)?$/.test(value);
    }

    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    const handlePaymentSubmit = async () => {
        if (!confirmationNumber || !amountPaid || !paymentDate) {
            alert('Llene todos los datos para continuar');
            return;
        } else {
            setIsLoading(true);
            try {
                const response = await sendPaymentConfirmation()
                if (response?.success) {
                    setIsLoading(false)
                    alert("Su pago fue confirmado exitosamente")
                } else if (response?.success == false) {
                    setIsLoading(false)
                    alert("Error: " + response?.status)
                } else {
                    alert("Ocurrió un problema intente más tarde")
                    setIsLoading(false)
                }

            } catch (err) {
                alert(err.message)
                setIsLoading(false)
            }
        }
    };

    function formatForAPI(date) {
        // Format the date as yyyy/mm/dd
        const formattedDate = date.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York', // GMT-4 timezone (Eastern Time)
        }).replace(/\//g, '-'); // Replace slashes with dashes

        return formattedDate;
    }

    const sendPaymentConfirmation = async () => { //todo: check if amountPaid is numerical before sending the call
        const data = {
            payment_object_id: paymentDocId,
            reference: confirmationNumber,
            amount: convertToDotDecimal(amountPaid),
            payment_type: "TRANSFER",
            date: formatForAPI(paymentDate),
            // cedula: selectedLetter + cedula,
            // phone_num: selectedCarrier + phoneNumber,
            // bank_code: selectedBank.split(' - ')[0],
            // bank_acc: bankAcc,
            api_type: 'banesco'
        }
        try {
            const tokenData = await requestToken()
            const response = await fetch('https://api.krece.app/process_payment/', { //agregar un catch aqui
                method: 'POST',
                // mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Token " + tokenData.token
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
        const month = date.toLocaleDateString('es-ES', { month: 'short' }); // Get the short month name
        const day = date.getDate(); // Get the day of the 
        return `${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;
    };

    const checkCedula = async () => {
        setFormError('');
        if (!cedula) {
            setFormError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'user_check/', token) //status, success, data 

            if (response?.success) {
                if (response.data.is_user_registered) {
                    await retrievePhoneNumber();
                } else {
                    await setLoginError('No encontramos a cliente asociado a esta cédula. Por favor contactar a soporte técnico.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const retrievePhoneNumber = async () => {
        try {
            const usersCollection = firestore.collection('Users');

            const queryUser = usersCollection
                .where('identification_number', '==', cedula)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    setPhoneNumber(doc.data()['phone_number']);
                    setEmailForAuthCode(doc.id);
                    sendAuthCodeToUser(doc.data()['phone_number']);
                })
            }).catch((err) => {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            })
        } catch (err) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendAuthCodeToUser = async (phoneNumberForAuthCode) => {
        try {
            const codeData = {
                phone_number: phoneNumberForAuthCode
            }
            const response = await sendPostRequest(codeData, 'request_auth_code/', token) //status, success, data
            if (response?.success) {
                setShowTokenSent(true)
                setIsLoading(false);
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const confirmAuthCodeFromUser = async () => {
        setFormError('');
        if (!authCode) {
            setFormError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const codeData = {
                confirmation_code: authCode
            }
            const response = await sendPostRequest(codeData, 'confirm_auth_code/', token) //status, success, data
            if (response?.success) {
                await getPayments();
            } else if (response?.success === false) {
                setFormError(response.status);
                setIsLoading(false);
            } else {
                setFormError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setFormError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }



    const GetPaymentObjects = () => {
        var isFuturePayment = true
        var checkedByInternal = false
        var isFirst = true
        const paym = []
        payments.forEach((item, index) => {
            if (item.paid && item.checked_by_internal_team) {
                checkedByInternal = true
                var PayObj = <span className={classes.textSmall3}>PAGADO</span>
            } else if (item.paid && !item.checked_by_internal_team) {
                checkedByInternal = false
                var PayObj = <span className={classes.textSmall4}>Por Confirmar</span>
            } else {
                <div><span className={classes.textSmall4}></span></div>
            }
            if (isFuturePayment && !item.paid && checkedByInternal) {
                PayObj = <button onClick={showPaymentScreen} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
                isFuturePayment = false
                checkedByInternal = true
            }
            // checkedByInternal = false
            if (isFirst && !item.paid) {
                PayObj = <button onClick={showPaymentScreen} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
            }
            isFirst = false
            paym.push(<div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                <span className={classes.textSmall2}>{formatDate(item.dueDate)}</span>
                {PayObj}
                <span className={classes.textSmall2}>{"$" + (item.actualAmount - item.amountPaid).toFixed(2)}</span>
            </div>)
        })
        return paym
    }

    const backToHome = () => {
        window.location.reload()
    }

    if (isLoading) {
        return (
            <div>
                <Container style={{
                    width: '750px',
                    maxWidth: '90%',
                    marginTop: 50,
                    marginBottom: 50,
                    marginHorizontal: 10,
                    boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                    paddingTop: '30px',
                    paddingBottom: '20px',
                    borderRadius: '8px',
                    backgroundColor: '#000000',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                        <BeatLoader color="#45C4D6" />
                    </div>
                </Container >
            </div>
        )
    }

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: '#000000' }}>
                <Toolbar style={{ backgroundColor: '#000000', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end', paddingBottom: 15, paddingTop: 15 }}>
                    <img className="krece-logo" src={image} alt="Krece logo" />
                    <Typography variant="h5" align="center" style={{ color: '#45C4D6' }}>
                        {clientName}
                    </Typography>
                    {page !== 1 ? <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={backToHome}
                        style={{ marginTop: '10px' }}
                    >
                        Salir
                    </Button> : <></>}
                </Toolbar>
            </AppBar>
            <Container style={{
                width: '750px',
                maxWidth: '90%',
                marginTop: 50,
                marginBottom: 50,
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: '30px',
                paddingBottom: '20px',
                borderRadius: '8px',
                backgroundColor: '#000000',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {page === 1 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Pagar mi cuota
                        </Typography>
                        {
                            !showTokenSent ? <form>
                                <TextField
                                    label="Cédula de identidad"
                                    type="cedula"
                                    name="cedula"
                                    value={cedula}
                                    onChange={(e) => setCedula(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                />
                                <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={checkCedula} fullWidth>
                                    Siguiente
                                </Button>
                                {formError && <p className={classes.formError}>{formError}</p>}
                            </form> : <form>
                                <p style={{ textAlign: 'center', color: "white", fontSize: 16 }}>{"Le enviamos un código de verificación por SMS al cliente al ******" + phoneNumber.substring(phoneNumber.length - 4)}</p>
                                <TextField
                                    label="Código de verificación"
                                    name="authCode"
                                    value={authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                ></TextField>
                                <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={confirmAuthCodeFromUser} fullWidth>
                                    Siguiente
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBack />}
                                    onClick={() => setShowTokenSent(false)}
                                    className={classes.welcomeButtons}
                                >
                                    Atrás
                                </Button>
                                {formError && <p className={classes.formError}>{formError}</p>}
                            </form>
                        }

                    </div>
                )}
                {page === 2 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1, padding: 20 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Mis Cuotas
                        </Typography>
                        {payments.length > 0 ? <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                                <span className={classes.textSmall5}>Vence</span>
                                <span className={classes.textSmall5}>Estado</span>
                                <span className={classes.textSmall5}>Debe</span>
                            </div>
                            {GetPaymentObjects()}
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                                <span className={classes.textSmall}>Saldo restante</span>
                                <span className={classes.textSmall}>{"$" + amountLeftToPay.toFixed(2)}</span>
                            </div>
                        </> : <div style={{ alignItems: 'center' }}><span className={classes.textSmall5}>No tienes cuotas pendientes!</span></div>}
                    </div>
                )}
                {page === 3 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Pago de cuota
                        </Typography>
                        <div style={{ padding: 20 }}>
                            <Typography className={classes.label}>Monto a pagar</Typography>
                            <Typography className={classes.label3}>{"$" + (amountToPayDollars).toFixed(2) + " / " + " Bs. " + (amountToPayDollars * exchangeRate).toFixed(2) + " (A tasa BCV)"}</Typography>
                            {/* <TextField
                                select
                                label="Método de pago"
                                name="payMethod"
                                value={payMethod}
                                onChange={(e) => setPayMethod(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                            >
                                <MenuItem value="Transferencia">Transferencia</MenuItem>
                                <MenuItem value="Zelle">Zelle</MenuItem>
                            </TextField> */}
                            <div>
                                <TextField
                                    label="Número de referencia"
                                    type="confirmationNumber"
                                    name="confirmationNumber"
                                    value={confirmationNumber}
                                    onChange={(e) => setConfirmationNumber(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                />
                            </div>
                            <div>
                                <TextField
                                    label="Monto del pago (en Bolivares)"
                                    type="amountPaid"
                                    name="amountPaid"
                                    value={amountPaid}
                                    onChange={(e) => setAmountPaid(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                />
                            </div>
                            <TextField
                                label="Fecha del pago"
                                name="paymentDate"
                                type="date"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handlePaymentSubmit} gutterBottom>
                                Enviar
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                startIcon={<ArrowBack />}
                                onClick={() => goToPage(2)}
                                style={{ marginTop: '10px' }}
                            >
                                Atrás
                            </Button>
                            <Typography variant="h5" className={classes.title}>
                                Detalles de las cuentas a pagar
                            </Typography>
                            <Typography className={classes.label}>
                                PagoMovil <br />
                                {'Banco: ' + pagoMovilData.bank}<br />
                                {'Rif: ' + pagoMovilData.rif}<br />
                                {'#Teléfono: ' + pagoMovilData.phoneNumber}<br />
                                <br />
                                Transferencia<br />
                                {'Cuenta: ' + transferData.account}<br />
                                {'Rif: ' + transferData.rif}<br />
                                {'Banco: ' + transferData.bank}<br />
                                {'Tipo: ' + transferData.type}<br />
                                <br />
                            </Typography>
                        </div>
                    </div>
                )}
                {page === 4 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Gracias por completar su pago
                        </Typography>
                        <Typography className={classes.label}>Hemos recibido la información de pago. Nuestro equipo confirmará su pago en las próximas 24 horas y lo podrá ver reflejado en la app</Typography>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(2)}
                            style={{ marginTop: '10px' }}
                        >
                            Volver
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#000000',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#000000',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
    textFields: {
        backgroundColor: 'white',
        color: '#000000',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        height: 700,
    },
    infoMessage: {
        color: "white"
    },
    title: {
        color: '#45C4D6',
        marginBottom: 20
    },
    textSmall: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    textSmall2: {
        fontSize: 14,
        color: 'white',
    },
    textSmall3: {
        fontSize: 14,
        color: '#45C4D6',
        fontWeight: 'bold'
    },
    textSmall4: {
        fontSize: 14,
        color: 'white',
        fontWeight: 'bold'
    },
    textSmall5: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14
    },
    paymentItem: {
        // width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        diplay: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    button: {
        width: 150,
        height: 40,
        borderRadius: 50,
        backgroundColor: '#45C4D6'
    },
    label: {
        fontSize: 20,
        marginBottom: 8,
        color: 'white'
    },
    label2: {
        fontSize: 20,
        marginBottom: 8,
        marginTop: 20,
        color: 'white'
    },
    label3: {
        fontSize: 16,
        marginBottom: 8,
        color: '#45C4D6',
        marginLeft: 20
    },
}));

export default UserPortal;
