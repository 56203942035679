import React, { useEffect, useState } from 'react';
import { Typography, Button, AppBar, Toolbar } from '@material-ui/core';
import image from '../images/krece_logo_new.png';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/firestore';
import firestore from '../firestore';
import './storeList.css'; // Import the CSS file

function StoreList({ route }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [storeList, setStoreList] = useState([]);
    const [cities, setCities] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showStore, setShowStore] = useState(false);
    const [currentCity, setCurrentCity] = useState('Todas');
    const [displayList, setDisplayList] = useState([]);
    const [selectedStore, setSelectedStore] = useState('');
    const [storeInfo, setStoreInfo] = useState([]);
    const [storeContact, setStoreContact] = useState('');

    useEffect(() => {
        (async () => {
            try {
                let storeList = [];
                let cities = ["Todas", "Caracas", "Valencia", "Maracay", "Margarita"];
                const querySnapshot = await firestore.collection('StoreInfo').get();
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.store_name !== 'Test Krece') {
                        if (data.isTop) {
                            storeList.unshift(data);
                        } else {
                            storeList.push(data);
                        }
                        data.cities = [];
                        data.sub_stores.forEach((item) => {
                            if (!cities.includes(item.city)) {
                                cities.push(item.city);
                            }
                            if (!data.cities.includes(item.city)) {
                                data.cities.push(item.city);
                            }
                        });
                    }
                });
                setStoreList(storeList);
                setDisplayList(storeList);
                setCities(cities);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                showAlert('Ocurrió un problema, por favor chequee su conexión a internet.');
            }
        })();
    }, []);

    const showAlert = (message) => {
        alert(message);
    };

    const filterList = (city) => {
        try {
            if (city === "Todas") {
                setDisplayList(storeList);
                setCurrentCity(city);
                return;
            }
            setCurrentCity(city);
            const filteredList = storeList.filter((item) => item.cities.includes(city));
            setDisplayList(filteredList);
        } catch (err) {
            setLoading(false);
            showAlert('Ocurrió un problema, por favor chequee su conexión a internet.');
        }
    };

    const openUrl = (url) => {
        window.open(url, "_blank");
    };

    const getStoreInfo = (store) => {
        try {
            setLoading(true);
            setSelectedStore(store);
            const storeI = storeList.filter((item) => item.store_name === store);
            setStoreContact(storeI[0].contact ? storeI[0].contact : storeI[0].phone_number);
            setStoreInfo(storeI[0].sub_stores);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showAlert('Ocurrió un problema, por favor chequee su conexión a internet.');
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-text">Cargando...</div>
            </div>
        );
    }

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: '#000000' }}>
                <Toolbar style={{ backgroundColor: '#000000', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end', paddingBottom: 15, paddingTop: 15 }}>
                    <img className="krece-logo" src={image} alt="Krece logo" />
                    <Typography variant="h5" align="center" style={{ color: '#45C4D6' }}>
                        Directorio de tiendas
                    </Typography>
                    {/* {page !== 1 ? <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={backToHome}
                        style={{ marginTop: '10px' }}
                    >
                        Salir
                    </Button> : <></>} */}
                </Toolbar>
            </AppBar>
            {selectedStore !== '' ? (
                <div className="selected-store-container">
                    <div className="header">
                        <div className="header-content">
                            <button className="back-button" onClick={() => setSelectedStore('')}>
                                Atrás
                            </button>
                            <Typography variant="h6">{selectedStore}</Typography>
                        </div>
                    </div>
                    <div className="store-details">
                        <div className="contact-container">
                            <Typography variant="h6">Contacto</Typography>
                            <div className="contact-info">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={typeof storeContact === 'string'}
                                    onClick={() => openUrl(storeContact.whatsapp_link)}
                                >
                                    WhatsApp
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={typeof storeContact === 'string'}
                                    onClick={() => openUrl(storeContact.instagram_link)}
                                >
                                    Instagram
                                </Button>
                            </div>
                        </div>
                        <div className="store-locations">
                            <Typography variant="h6">Sucursales</Typography>
                            <div className="store-locations-list">
                                {storeInfo.map((item) => (
                                    <div className="store-location-item" key={item.store_name}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{item.store_name}</Typography>
                                        <Typography variant="body1">{item.city}</Typography>
                                        <Typography variant="body1">{item.location}</Typography>
                                        <Typography variant="body1">{item.hours_of_operation}</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="store-list-container">
                    <div className="city-filter">
                        {cities.map((city) => (
                            <Button
                                key={city}
                                variant="contained"
                                color={currentCity === city ? 'primary' : 'default'}
                                onClick={() => filterList(city)}
                                style={{ margin: '5px' }}
                            >
                                {city}
                            </Button>
                        ))}
                    </div>
                    <div className="store-grid">
                        {displayList.map((item) => (
                            <div className="store-grid-item" key={item.store_name} onClick={() => getStoreInfo(item.store_name)}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>{item.store_name}</Typography>
                                <img src={item.logo_url} alt={item.store_name} style={{ width: '100px', height: '100px' }} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default StoreList;
